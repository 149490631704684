import { Component, inject, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { Actions, ofActionDispatched, Store } from '@ngxs/store';
import {
  catchError,
  distinctUntilChanged,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import {
  BroadcastChannelService,
  IBroadcastMessage,
  USER_BROADCAST_CHANNEL,
} from './shared/common/broadcast-channel';
import { SvgIconComponent } from 'angular-svg-icon';
import { MessageService } from 'primeng/api';

import { onDestroy$ } from './shared/utils';
import { LocalStorage, RETURN_URL, STORE_TOKEN_KEY } from './core/storage';
import { AppUiLoadingAction, AppUiSelector } from './core/stores/app-ui';
import { LoginAction, LogoutSuccessful } from './core/stores/user';
import { ToastViewComponent } from './shared/components/toast/toast.view';
import { ConfirmPopupViewComponent } from './shared/components/confirm-popup/confirm-popup.view';
import { SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';
import { AppTokenData } from './core/storage/app-token-data';
import { AuthRepository } from './core/auth/data/repository/auth.repositoty';
import { AuthService } from './core/auth/data/services/auth.service';
import { NgxPermissionsModule } from 'ngx-permissions';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    ToastViewComponent,
    ConfirmPopupViewComponent,
    SvgIconComponent,
    NgxPermissionsModule,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  providers: [{ provide: AuthRepository, useClass: AuthService }],
})
export class AppComponent implements OnInit {
  private _store = inject(Store);
  private _actions = inject(Actions);
  private _broadcastChannelService = inject(BroadcastChannelService);
  private readonly _onDestroy$ = onDestroy$();
  private _socialAuthService: SocialAuthService = inject(SocialAuthService);
  private _messageService = inject(MessageService);
  private _authRepository = inject(AuthRepository);

  protected isLoadingGlobal = this._store.selectSignal(AppUiSelector.isLoading);
  private uuid = this._store.selectSignal(AppUiSelector.uuid);
  get token() {
    return LocalStorage.get(STORE_TOKEN_KEY);
  }

  protected _noteService = new ToastViewComponent();

  ngOnInit(): void {
    this.loginWithGoogle();

    // Broadcast Channel
    this._broadcastChannelService.listenBroadcast(
      USER_BROADCAST_CHANNEL.name,
      (msg: IBroadcastMessage) => {
        // Ignore current tab message
        if (this.uuid() === msg.uuid) {
          return;
        }

        // Always Handle logout message event token is invalid
        if (msg.action === USER_BROADCAST_CHANNEL.action.LogOut) {
          LocalStorage.remove(STORE_TOKEN_KEY);
          window.location.href = '/';
        }

        // Ignore message if token is invalid
        if (this.token?.accessToken !== msg.token) {
          return;
        }

        if (msg.action === USER_BROADCAST_CHANNEL.action.LogIn) {
          window.location.reload();
        }
      },
    );

    this._actions
      .pipe(
        ofActionDispatched(LogoutSuccessful),
        tap(() => {
          const broadcastData = {
            action: USER_BROADCAST_CHANNEL.action.LogOut,
            uuid: this.uuid(),
            token: this.token?.accessToken,
          };
          this._broadcastChannelService.broadcastMessage(
            USER_BROADCAST_CHANNEL.name,
            broadcastData,
            true,
          );
        }),
        takeUntil(this._onDestroy$),
      )
      .subscribe();
  }

  private loginWithGoogle() {
    // Login with google account (SSO)
    this._socialAuthService.authState
      .pipe(
        //  call API to get access Token and refreshToken
        switchMap((user: SocialUser) => {
          this._store.dispatch(new AppUiLoadingAction(true));
          return this._authRepository.login(user.idToken);
        }),
        catchError(err => {
          this._store.dispatch(new AppUiLoadingAction(false));
          throw err;
        }),
        distinctUntilChanged(),
        takeUntil(this._onDestroy$),
      )
      .subscribe({
        next: res => {
          if (res?.accessToken) {
            const token = {
              accessToken: res.accessToken || '',
              refreshToken: res.refreshToken || '',
            } as AppTokenData;

            // find url to navigate
            const search = decodeURIComponent(window.location.search.slice(1));
            let returnUrl = '/';
            if (search?.includes(RETURN_URL + '=')) {
              returnUrl = search.split('=')[1] || '/';
            }
            this._store.dispatch(new LoginAction(token, returnUrl));
          }
          this._store.dispatch(new AppUiLoadingAction(false));
        },
        error: err => {
          this._store.dispatch(new AppUiLoadingAction(false));
          this._noteService.toastNoti("error", err?.message);
        },
      });
  }
}
