import { Routes } from '@angular/router';
import { APP_PAGE } from './core/configurations';
import { authGuardCanActivateFn } from './core/guard/auth.guard';

export const routes: Routes = [
  {
    path: APP_PAGE.Auth,
    loadChildren: () =>
      import('./core/auth/auth.routes').then((mod) => mod.routes),
  },
  {
    path: '',
    canActivate: [authGuardCanActivateFn],
    loadChildren: () =>
      import('./core/shell/shell.routes').then((m) => m.routes),
  },
];
