import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

@Component({
  selector: 'tfgh-confirm-popup-view',
  standalone: true,
  imports: [ConfirmDialogModule],
  templateUrl: './confirm-popup.view.html',
  styleUrl: './confirm-popup.view.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmPopupViewComponent {
  /*
    # User manual
    import 'ConfirmationService' into the component used => private _confirmationService = inject(ConfirmationService);

    //
    this._confirmationService.confirm({
      message: 'Here is the description of the popup',
      header: 'Popup Title',
      acceptLabel: 'Confirm',
      rejectLabel: 'Cancel',
      acceptButtonStyleClass: 'p-button-primary',
      rejectButtonStyleClass: 'p-button-secondary',
      accept: () => {
        // Do something...
      },
      reject: () => {
        // Do something...
      },
    });
  */
}
