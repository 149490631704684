<p-confirmDialog #cd>
  <ng-template pTemplate="headless" let-message>
    <div class="content-confirm p-8 bg-white rounded-lg text-center">
      <h5 class="font-medium text-2xl">{{ message.header }}</h5>
      <p class="mt-3 text-base text-secondary-2" [innerHTML]="message.message">
      </p>

      <div class="flex justify-center gap-5 mt-8">
        <p-button
          [styleClass]="'min-w-[160px] ' + cd.confirmation?.rejectButtonStyleClass || 'p-button-secondary'"
          [label]="cd.confirmation?.rejectLabel || 'Cancel'"
          size="small"
          (onClick)="cd.reject()"
        />
        <p-button
          [styleClass]="'min-w-[160px] ' + cd.confirmation?.acceptButtonStyleClass || 'p-button-primary'"
          [label]="cd.confirmation?.acceptLabel || 'Confirm'"
          size="small"
          (onClick)="cd.accept()"
        />
      </div>
    </div>
  </ng-template>
</p-confirmDialog>
