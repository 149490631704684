import { inject, Injectable, OnDestroy } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';

import { filter, finalize, Observable, Subject, throwError, timer } from 'rxjs';
import {
  catchError,
  concatMap,
  distinctUntilChanged,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { isNullUndefinedOrEmpty } from '@app/shared/utils/object.utility';
import { LocalStorage, STORE_TOKEN_KEY } from '@app/core/storage';
import { skipNullOrUndefined } from '@app/shared/utils';
import {
  BroadcastChannelService,
  TOKEN_BROADCAST_CHANNEL,
} from '@app/shared/common/broadcast-channel';
import { AppUiSelector } from '../stores/app-ui';
import { LogoutAction, UserSelector } from '../stores/user';
import { LoadDataSourceResponse } from '@app/shared/models';
import { TFGH_END_POINTS } from '@app/shared/common/end-points';
import { IRefreshTokenResponse } from '@app/shared/data';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor, OnDestroy {
  //#region #Injection
  private _store: Store = inject(Store);
  private _httpClient = inject(HttpClient);
  private _broadcastChannelService = inject(BroadcastChannelService);
  //#endregion

  private readonly _isDebug: boolean;
  private _isTokenRefreshing = false;

  private readonly _onStartRefreshToken$ = new Subject<string>();
  private readonly _onTokenRefreshed$ =
    new Subject<IRefreshTokenResponse | null>();

  private get _appUuid(): string {
    return this._store.selectSnapshot(AppUiSelector.uuid);
  }

  private get _token() {
    return LocalStorage.get(STORE_TOKEN_KEY);
  }

  private _currentUser = this._store.selectSignal(UserSelector.currentUser);

  constructor() {
    this.subscribeRefreshTokenStart();

    this.subscribeRefreshTokenMessages();
  }

  ngOnDestroy() {
    this._onTokenRefreshed$?.complete();
    this._onStartRefreshToken$?.complete();
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    // Only refresh token if the Remember Me is checked
    return next.handle(request).pipe(
      catchError((err: Error) => {
        // Ignore handle if user is not logged
        if (isNullUndefinedOrEmpty(this._token?.accessToken)) {
          return throwError(() => err);
        }

        const error = err as HttpErrorResponse;

        // Ignore handle for refresh token, or logout requests
        if (error.url?.includes(`/refresh`) || error.url?.includes(`/logout`)) {
          return throwError(() => err);
        }

        // Check is token expired
        const tokenExpired = error.status === HttpStatusCode.Unauthorized;

        // Handle refresh token if token is expired
        if (tokenExpired) {
          console.log('RefreshTokenInterceptor - Token is expired');
          // 1.  Mark this request as waiting for refresh token response
          // And start refresh token process
          if (!this._isTokenRefreshing) {
            this._onTokenRefreshed$.next(null);
            this._onStartRefreshToken$.next(this._token?.refreshToken);
          }
          // 2. waiting for token refreshed message
          return this.waitingRefreshTokenResponse(request, next);
        }

        // Throw error
        return throwError(() => err);
      }),
    );
  }

  private updateRequestToken(
    req: HttpRequest<unknown>,
    token: string,
  ): HttpRequest<unknown> {
    const newHeaders: HttpHeaders = req.headers.set(
      'Authorization',
      'Bearer ' + token,
    );

    return req.clone({ headers: newHeaders });
  }

  private waitingRefreshTokenResponse(
    req: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return this._onTokenRefreshed$.pipe(
      skipNullOrUndefined(),
      take(1),
      switchMap((jwt: IRefreshTokenResponse) => {
        if (this._isDebug) {
          console.log('RefreshTokenInterceptor - Refresh token api responded');
        }
        console.log("jwt", jwt)
        return next.handle(this.updateRequestToken(req, jwt.accessToken));
      }),
    );
  }

  /**
   * Subscribe and handle refresh token message from other tabs
   * */
  private subscribeRefreshTokenMessages() {
    this._broadcastChannelService.listenBroadcast(
      TOKEN_BROADCAST_CHANNEL.name,
      message => {
        if (this._isDebug) {
          console.log(
            'RefreshTokenInterceptor - Received token refreshed message',
          );
        }

        // Ignore if message is from current tab
        if (message.uuid === this._appUuid) {
          return;
        }

        // If fail on refresh token, logout
        if (
          message.action === TOKEN_BROADCAST_CHANNEL.action.refreshTokenError
        ) {
          this._store.dispatch(new LogoutAction());
          return;
        }

        // If refresh token success, update token and recall API
        if (message.action === TOKEN_BROADCAST_CHANNEL.action.tokenRefreshed) {
          const data = message.data as IRefreshTokenResponse;
          if (data.accessToken === this._token?.accessToken) {
            return;
          }
          // Update stored token if message is not from current tab, but tenant is matched
          LocalStorage.set(STORE_TOKEN_KEY, data);

          this._onTokenRefreshed$.next(data);
        }
      },
    );
  }

  private subscribeRefreshTokenStart() {
    this._onStartRefreshToken$
      .pipe(
        skipNullOrUndefined(),
        distinctUntilChanged(),
        filter(token => token === this._token?.refreshToken),
      )
      .subscribe(refreshToken => {
        this._isTokenRefreshing = true;

        this._httpClient
          .get<LoadDataSourceResponse<IRefreshTokenResponse>>(
            `${TFGH_END_POINTS.API_BASE}/refresh-token`,
            {
              // get user from UserSelector
              params: {
                token: refreshToken,
                userId: this._currentUser()?.id || 0,
              },
            },
          )
          .pipe(
            tap((token: LoadDataSourceResponse<IRefreshTokenResponse>) => {
              if (token && token.data) {
                // 1. Broadcast token refreshed message
                this.broadcastTokenRefreshedMessage(token.data);

                // 2. Update token data
                LocalStorage.set(STORE_TOKEN_KEY, token.data);

                // 3. Notify token refreshed
                this._onTokenRefreshed$.next(token.data);
              } else {
                console.log("Log out")
                this._store.dispatch(new LogoutAction());
                return;
              }
            }),
            // Logout if refresh token request return error
            catchError((error: Error) => {
              // On refresh token error
              return this.onRefreshTokenError(error, refreshToken);
            }),
            // Finalize: Set is token refreshing = false
            finalize(() => {
              this._isTokenRefreshing = false;
            }),
          )
          .subscribe();
      });
  }

  onRefreshTokenError(error: Error, refreshToken: string) {
    // NOTE: Delay 3s to waiting for other tab finish refresh token call
    return timer(3000).pipe(
      concatMap(() => {
        if (this._isDebug) {
          console.log(
            'RefreshTokenInterceptor: Error cuz call multiple refresh token at the same time',
          );
        }

        if (refreshToken === this._token?.refreshToken) {
          return this._store.dispatch(new LogoutAction());
        }

        if (this._isDebug) {
          console.log(
            'RefreshTokenInterceptor: Token already refreshed. Start to retry original API',
          );
        }

        this._onTokenRefreshed$.next(this._token as IRefreshTokenResponse);
        return throwError(() => error);
      }),
    );
  }

  //#region #Broadcast refresh token messages
  private broadcastTokenRefreshedMessage(token: IRefreshTokenResponse) {
    this._broadcastChannelService.broadcastMessage(
      TOKEN_BROADCAST_CHANNEL.name,
      {
        uuid: this._appUuid,
        action: TOKEN_BROADCAST_CHANNEL.action.tokenRefreshed,
        token: this._token?.accessToken,
        data: token,
      },
    );
  }

  //#endregion
}
