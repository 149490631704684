import { inject, Inject, Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';

import { Store } from '@ngxs/store';
import { finalize, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MessageService } from 'primeng/api';

import { LocalStorage, STORE_TOKEN_KEY } from '@app/core/storage';
import { ForbiddenRedirectAction, LogoutAction } from '../stores/user';

interface IErrorResponse {
  message: string;
  httpStatusCode: HttpStatusCode;
}

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  private _isLoggingOut = false;
  private _messageService = inject(MessageService);

  constructor(private store: Store) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    // Check if already logged in
    const accessToken = LocalStorage.get(STORE_TOKEN_KEY)?.accessToken || '';

    // Append authorization header
    if (accessToken) {
      let headers: HttpHeaders = request.headers.set(
        'Authorization',
        `Bearer ${accessToken}`,
      );

      request = request.clone({ headers: headers });
    }

    // Only refresh token if the Remember Me is checked
    return next.handle(request).pipe(
      catchError((error: Error) => {
        const errorResponse = error as HttpErrorResponse;
        return this.handleRequestError(error, errorResponse);
      }),
    );
  }

  private handleRequestError(
    originError: Error,
    httpResponseError: HttpErrorResponse,
  ): Observable<HttpEvent<unknown>> {
    switch (httpResponseError.status as HttpStatusCode) {
      // 401: Unauthorized
      case HttpStatusCode.Unauthorized: {
        if (!this._isLoggingOut) {
          this._isLoggingOut = true;
          this.store.dispatch(new LogoutAction()).pipe(
            finalize(() => {
              this._isLoggingOut = false;
            }),
          );
        }
        break;
      }

      // 403: Forbidden
      case HttpStatusCode.Forbidden: {
        this.store.dispatch(new ForbiddenRedirectAction());
        break;
      }
    }

    const errorMessage = this.handleHttpErrorResponse(httpResponseError);

    return throwError(() => errorMessage);
  }

  private handleHttpErrorResponse(response: HttpErrorResponse): IErrorResponse {
    //
    if (response.status === HttpStatusCode.Forbidden) {
      return {
        message: 'You do not have permission to perform this action',
        httpStatusCode: response.status,
      };
    }

    if (
      response.status === HttpStatusCode.PermanentRedirect ||
      response.status === HttpStatusCode.TemporaryRedirect
    ) {
      // Return as no error if there are no error
      if (response.error) {
        const message = this.getErrorMessage(response);
        this._messageService.add({
          severity: 'info',
          detail: message.message,
        });
      }

      // Return void to ignore error and continue process
      return {
        message: this.getErrorMessage(response).message,
        httpStatusCode: response.status,
      };
    }

    if (response.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error(
        'ApiInterceptor - An error occurred:',
        response.error.message,
      );
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
    }

    const error = this.getErrorMessage(response);

    switch (response.status) {
      case HttpStatusCode.BadGateway: {
        // BE is deploying
        error.message = `[502] BadGateway`;
        break;
      }
      case HttpStatusCode.InternalServerError:
      case HttpStatusCode.NotImplemented:
      case HttpStatusCode.ServiceUnavailable:
      case HttpStatusCode.GatewayTimeout:
      case HttpStatusCode.HttpVersionNotSupported:
      case HttpStatusCode.VariantAlsoNegotiates:
      case HttpStatusCode.InsufficientStorage:
      case HttpStatusCode.LoopDetected:
      case HttpStatusCode.NotExtended:
      case HttpStatusCode.NetworkAuthenticationRequired: {
        error.message = `[${response.status}] BE API: ${JSON.stringify(error)}`;
        break;
      }
    }

    return error;
  }

  private getErrorMessage(response: HttpErrorResponse): IErrorResponse {
    console.log(response);
    let errorMessage: string;
    if (!!response.error && !!response.error.message) {
      errorMessage = response.error.message;
    } else {
      if (response.error && typeof response.error === 'string') {
        try {
          const jsonError = JSON.parse(response.error);
          errorMessage = jsonError.message ?? jsonError;
        } catch (parseError) {
          // Ignore if can't parse
          errorMessage =
            'Unknown error. Please try again or contact technical support for assistance!';
        }
      } else {
        errorMessage =
          response.error.errorMessage;
      }
    }

    return {
      message: errorMessage,
      httpStatusCode: response.status,
    } as IErrorResponse;
  }
}
