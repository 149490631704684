import {
  APP_INITIALIZER,
  ApplicationConfig,
  importProvidersFrom,
  Injector,
  provideZoneChangeDetection,
} from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';

import { provideStore, Store } from '@ngxs/store';
import { provideAngularSvgIcon } from 'angular-svg-icon';
import {
  ConfirmationService,
  MessageService,
  PrimeNGConfig,
} from 'primeng/api';
import {
  GoogleLoginProvider,
  SocialAuthServiceConfig,
} from '@abacritt/angularx-social-login';
import { withNgxsRouterPlugin } from '@ngxs/router-plugin';
import { NgxPermissionsModule } from 'ngx-permissions';

import { ApiInterceptor } from './core/interceptors/api.interceptor';
import { RefreshTokenInterceptor } from './core/interceptors/refresh-token.interceptor';
import { routes } from './app.routes';
import { AppUiState } from './core/stores/app-ui';
import { UserState } from './core/stores/user';
import { environment } from '@environments/environment';
import { initApplication } from './core/configurations/app-initializer/init-application.configuration';

export const configPrimengGlobal = [MessageService, ConfirmationService];

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    provideStore([AppUiState, UserState], withNgxsRouterPlugin()),
    provideAngularSvgIcon(),
    provideRouter(routes),
    importProvidersFrom(NgxPermissionsModule.forRoot()),
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.googleProviderId),
          },
        ],
        onError: err => {
          console.error(err);
        },
      } as SocialAuthServiceConfig,
    },
    configPrimengGlobal,
    {
      provide: APP_INITIALIZER,
      useFactory: initApplication,
      deps: [Injector, PrimeNGConfig, Store],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true,
    },
  ],
};
