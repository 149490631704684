import { Injectable, OnDestroy } from '@angular/core';
import { IBroadcastMessage } from './models/i-broadcast-message.model';

@Injectable({
  providedIn: 'root'
})
export class BroadcastChannelService implements OnDestroy {
  private _isSupported: boolean;
  private _channels: Map<string, BroadcastChannel>;

  constructor() {
    this._isSupported = typeof BroadcastChannel !== 'undefined';
    this._channels = new Map<string, BroadcastChannel>();
  }

  public broadcastMessage(channelName: string, message: IBroadcastMessage, closeAfterSent = false): void {
    this._ensureChannel(channelName).postMessage(message);
    if (closeAfterSent) {
      this._closeChannel(channelName);
    }
  }

  public listenBroadcast(channelName: string, handler: (message: IBroadcastMessage) => void): void {
    const channel = this._ensureChannel(channelName);
    channel.addEventListener('message', (event: MessageEvent<IBroadcastMessage>) => handler(event.data));
  }

  ngOnDestroy(): void {
    this._channels.forEach(channel => channel.close());
  }

  private _ensureChannel(channelName: string): BroadcastChannel {
    if (!this._isSupported) {
      console.log('BroadcastChannel not supported in this browser');
    }
    let channel = this._channels.get(channelName);
    if (!channel) {
      channel = new BroadcastChannel(channelName);
      this._channels.set(channelName, channel);
    }
    return channel;
  }

  private _closeChannel(channelName: string): void {
    const channel = this._channels.get(channelName);
    if (channel) {
      channel.close();
      this._channels.delete(channelName);
    }
  }
}