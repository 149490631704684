import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  RouterStateSnapshot,
} from '@angular/router';

import { Store } from '@ngxs/store';

import { LoginRedirectAction, UserSelector } from '../stores/user';

export const authGuardCanActivateFn: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const returnUrl = state?.url;
  const store = inject(Store);
  const isLoggedIn = store.selectSignal(UserSelector.isLoggedIn);
  if (isLoggedIn()) {
    return true;
  }
  store.dispatch(
    new LoginRedirectAction(returnUrl ? { url: returnUrl } : undefined),
  );
  return false;
};
