type TokenChannelAction = {
	tokenRefreshed: string;
	refreshTokenError: string;
};

export const TOKEN_BROADCAST_CHANNEL: {
	name: string;
	action: TokenChannelAction;
} = {
	name: 'TokenChannel',
	action: {
		tokenRefreshed: 'ws-reconnect-on-token-refreshed',
		refreshTokenError: 'refresh-token-error',
	},
};
