<router-outlet />
<tfgh-toast-view></tfgh-toast-view>
<tfgh-confirm-popup-view></tfgh-confirm-popup-view>

@if (isLoadingGlobal()) {
  <div
    style="background-color: rgba(0, 0, 0, 0.3)"
    class="fixed top-0 left-0 h-full w-full z-[10000]"
  >
    <svg-icon
      class="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2"
      [svgStyle]="{ 'width.px': 100, 'height.px': 100 }"
      src="icons/loading.svg"
    ></svg-icon>
  </div>
}
