import { Injector } from '@angular/core';
import { LoadCurrentUserAction, UserSelector } from '@app/core/stores/user';
import { Store } from '@ngxs/store';
import { PrimeNGConfig } from 'primeng/api';

export function initApplication(
  inject: Injector,
  config: PrimeNGConfig,
  store: Store,
): Function {
  config.ripple = true;
  return () => {
    if (!store.selectSignal(UserSelector.isLoggedIn)()) {
      return true;
    }
    return new Promise(resolve => {
      store.dispatch(new LoadCurrentUserAction());
      resolve(true);
    });
  };
}
